@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}
/* For Webkit browsers like Chrome, Safari, and Edge */
::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
  }
  
  ::-webkit-scrollbar-track {
    background: #E6E6FA; /* Transparent track */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #c5b6e8; /* Color of the scrollbar thumb */
    border-radius: 10px;       /* Round the edges of the thumb */
  }
  
/* For Firefox */
.custom-scrollbar {
    scrollbar-color: #c5b6e8 transparent; /* Thumb color and track color */
    scrollbar-width: thin;                /* Reduce the scrollbar width */
  }

.custom-scrollbar:hover {
scrollbar-width: thin; /* Show a thin scrollbar on hover */
}
  
.swiper-pagination-bullet-active {
  background-color: #84127c !important; /* Use your desired color */
}

.flip-container {
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Provides the 3D space for the flip */
  position: relative; /* Ensure the flipping stays inside the about section */
  overflow: hidden; /* Hide overflow to prevent content from going outside */
}

.flipper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg); /* The 3D flip rotation */
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the backside when flipped */
}

.back {
  transform: rotateY(180deg); /* Rotate the back element 180 degrees */
}

section {
  position: relative;
  z-index: 1; /* Ensure the section has its own z-index */
}

h1, p {
  z-index: 2; /* Text and other content should stay above the flipped container */
}
